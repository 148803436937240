import React from "react";

const Shop = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
                backgroundColor: "#e3e3e3",
                margin: "24px",
                padding: "18px"
            }}
        >
            <h1>Coming soon...</h1>
        </div>
    );
};

export default Shop;